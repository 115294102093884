<template>
  <div class="lecture">
    <div class="events-inner">
      <div class="lecture-list">
        <div class="list-content">
          <div
            v-for="item in resultList"
            :key="item.id"
            class="list-item"
            @click="$router.push(`/web/events/${item.id}`)"
          >
            <div class="left">
              <div class="title">{{ item.title }}</div>
              <div class="desc">{{ item.description }}</div>
              <div class="date">
                {{ item.cdatetime }}
                <span class="status"
                  >审核状态：<span>{{ status[item.status] }}</span></span
                >
              </div>
            </div>
            <div class="right">
              <div class="price">
                悬赏：<span>{{ item.price }}</span>
              </div>
              <div class="comment">
                <div
                  class="btn blue"
                  @click.stop="$router.push(`/web/publish-event?id=${item.id}`)"
                >
                  编辑
                </div>
                <div class="btn red" @click.stop="deleteItem(item.id)">
                  删除
                </div>
              </div>
            </div>
          </div>
        </div>
        <el-pagination
          class="pager"
          background
          layout="prev, pager, next"
          :page-count="maxPage"
          @prev-click="fetchList"
          @next-click="fetchList"
          @current-change="fetchList"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { Pagination } from "element-ui";
import otherApis from "@/apis/other";

const TABS = [
  {
    label: "全部",
    route: "",
    left: "43px"
  }
];

const status = {
  0: "未审核",
  1: "上架",
  2: "下架"
};

export default {
  name: "Events",
  components: {
    "el-pagination": Pagination
  },
  data() {
    return {
      status,
      tabs: TABS,
      activeTab: TABS[0],
      page: 0,
      maxPage: 0,
      finished: false,
      loading: false,
      resultList: [],
      currApi: otherApis.articleIndex,
      catId: ""
    };
  },
  created() {
    // this.fetchList();
    this.fetchList();
  },
  methods: {
    isActiveTab(tab) {
      return this.activeTab.route === tab.route;
    },

    async fetchList(page) {
      this.page = page;
      try {
        const {
          data: { article_list, max_page }
        } = await this.currApi({ page: this.page, is_my: 1 });
        this.maxPage = max_page;
        this.resultList = article_list;
      } catch (error) {
        this.finished = true;
        console.log(error);
      }
      this.loading = false;
    },

    refreshList() {
      this.page = 1;
      this.finished = false;
      this.loading = true;
      this.resultList = [];
      this.fetchList();
    },
    deleteItem(id) {
      this.$confirm("确定删除吗").then(async () => {
        await otherApis.articleDel({ id });
        this.$message.success("删除成功");
        this.refreshList();
      });
    }
  }
};
</script>

<style lang="scss" scope>
.lecture {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  padding: 15px 0;
  height: calc(100vh - 70px);

  .publish {
    width: 70px;
    height: 30px;
    background: #ca0a15;
    border-radius: 4px;
    font-size: 14px;
    color: #f7f7f7;
    line-height: 30px;
    text-align: center;
    float: right;
    margin-top: 10px;
    cursor: pointer;
  }

  .tabs {
    width: 100%;
    height: 50px;
    box-sizing: border-box;
    padding: 0 20px;
    position: relative;

    .underline {
      width: 18px;
      height: 2px;
      background: #ca0a15;
      position: absolute;
      bottom: 1px;
      transition: left 0.3s ease;
    }

    &::after {
      position: absolute;
      bottom: 0;
      left: 20px;
      content: "";
      display: block;
      width: calc(100% - 40px);
      height: 1px;
      background-color: #f0f0f0;
    }

    .tab-item {
      padding: 0 20px;
      font-size: 14px;
      color: #666666;
      line-height: 50px;
      cursor: pointer;

      &.active {
        color: #ca0a15;
      }
    }
  }

  .events-inner {
    width: 1200px;
    height: 100%;
    background: #ffffff;
    border-radius: 4px;
    display: flex;
    flex-flow: column nowrap;
  }
}

.lecture-list {
  height: calc(100% - 46px);
  padding-top: 20px;

  .list-content {
    padding: 0 20px;
    display: flex;
    flex-flow: column nowrap;
    overflow: auto;
    height: calc(100% - 60px);
    // flex: none;

    .list-item {
      cursor: pointer;
      flex: none;
      height: 100px;
      background: #f7f7f7;
      border-radius: 4px;
      margin-bottom: 10px;
      padding: 14px 20px;
      box-sizing: border-box;
      line-height: 1;
      display: flex;
      justify-content: space-between;

      .right {
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        align-items: flex-end;

        .price {
          font-size: 18px;
          color: #333333;

          span {
            color: #ca0a15;
          }
        }

        .comment {
          font-size: 12px;
          color: #b5b5b5;
          display: flex;

          .btn {
            &.blue {
              color: #21a3fe;
            }

            &.red {
              color: #f02b2b;
            }
          }

          div {
            margin-left: 10px;
          }
          span {
            color: #ca0a15;
          }
        }
      }

      .title {
        font-size: 18px;
        color: #333333;
        white-space: nowrap;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .desc {
        margin-top: 12px;
        font-size: 14px;
        color: #666666;
        white-space: nowrap;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .date {
        margin-top: 19px;
        font-size: 12px;
        color: #b5b5b5;
      }

      .status {
        span {
          color: #ff5500;
        }
      }

      &:last-of-type {
        border-bottom: none;
      }
    }
  }

  .pager {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
}
</style>
